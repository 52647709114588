

































































































































import api from "@/api/index"; //ABP API接口
import {Vue, Component} from "vue-property-decorator";

import {ActivityDto, PagedResultDto} from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import ExportButton from "@/components/ExportButton/index.vue";

@Component({
  name: "ActivityApplyInfoList",
  components: {
    PagedTableView,
    ExportButton
  },
})
export default class ActivityList extends Vue {
  queryForm = {
    title: "",
    activityStartTime: "",
    activityEndTime: "",
  };

  id = 0;

  created() {
    // api.enumService
    //   .getValues({ typeName: "ActivityStatus" })
    //   .then((res: any) => {
    //     this.activityStatusList = res;
    //   });
  }

  // 获取表数据
  fetchData(params: any) {
    console.log("test:" + params);
    return api.activity.getAll(params);
  }

  handleCheckIn(index: number, row: ActivityDto) {
    this.$router.push({
      name: "ActivityCheckIn",
      params: {id: `${row.id!}`},
      query: {},
    });
  }

  handleDetail(index: number, row: ActivityDto) {
    this.$router.push({
      name: "ActivityDetail",
      params: {id: `${row.id!}`},
      query: {},
    });
  }


  hasDisplayPermission(permissionCode: string) {
    return this.hasPermission(permissionCode);
  }
}
